// ** Icons Import
import {
  Circle,
  Shield,
  Archive,
  UserCheck,
  ShieldOff,
  Tool,
  Codesandbox,
  Trello,
  Upload,
  Clipboard,
  Server
} from 'react-feather'
import { IS_ADMIN, IS_MENTOR } from '../../utility/constants'

export default [
  // {
  //   header: 'Settings',
  //   show: [IS_ADMIN]
  // },
  {
    id: 'Process',
    title: 'Process',
    icon: <Codesandbox size={20} />,
    show: [IS_ADMIN, IS_MENTOR],
    children: [
      {
        id: 'assigntopic',
        title: 'Employee Board',
        icon: <Circle size={12} />,
        show: [IS_ADMIN, IS_MENTOR],
        navLink: '/apps/usertopics'
      },
      {
        id: 'assigntask',
        title: 'Employee Task',
        icon: <Circle size={12} />,
        show: [IS_ADMIN, IS_MENTOR],
        navLink: '/apps/usertasks'
      },
      {
        id: 'usertechnology',
        title: 'Employee Skill Set',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/usersettings/usertechnology'
      },
      {
        id: 'user-project',
        title: 'Project Teams',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/usersettings/user-project'
      },
      {
        id: 'usermentor',
        title: 'Mentor Teams',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/usersettings/usermentor'
      }
      // {
      //   id: 'userrole',
      //   title: 'User Role',
      //   icon: <Circle size={12} />,
      //   show: [IS_ADMIN],
      //   navLink: '/usersettings/userrole'
      // }
    ]
  },
  {
    id: 'master',
    title: 'Masters',
    icon: <Archive size={20} />,
    show: [IS_ADMIN, IS_MENTOR],
    children: [
      {
        id: 'user',
        title: 'Employee',
        icon: <Circle size={12} />,
        show: [IS_ADMIN, IS_MENTOR],
        navLink: '/apps/master/user'
      },

      {
        id: 'topic',
        title: 'Topic',
        icon: <Circle size={12} />,
        show: [IS_ADMIN, IS_MENTOR],
        navLink: '/apps/master/topic'
      },
      {
        id: 'subtopic',
        title: 'Sub Topic',
        icon: <Circle size={12} />,
        show: [IS_ADMIN, IS_MENTOR],
        navLink: '/apps/master/subtopic'
      },
      {
        id: 'task',
        title: 'Task',
        icon: <Circle size={12} />,
        show: [IS_ADMIN, IS_MENTOR],
        navLink: '/apps/master/task'
      },
      {
        id: 'technology',
        title: 'Technology',
        icon: <Circle size={12} />,
        show: [IS_ADMIN, IS_MENTOR],
        navLink: '/apps/master/technology'
      },
      {
        id: 'category',
        title: 'Category',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/apps/master/category'
      },
      {
        id: 'module',
        title: 'Module',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/apps/master/module'
      },
      {
        id: 'project',
        title: 'Project',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/apps/master/project'
      },
      {
        id: 'mentorMaster',
        title: 'Mentor',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/apps/master/mentormaster'
      },
      {
        id: 'reasonMaster',
        title: 'Reason',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/apps/master/reason'
      },
      {
        id: 'branch',
        title: 'Branch',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/apps/master/branch'
      },
      {
        id: 'designation',
        title: 'Designation',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/apps/master/designation'
      }
    ]
  },
  {
    id: 'roles-permissions',
    title: 'Settings',
    icon: <Shield size={20} />,
    show: [IS_ADMIN],
    children: [
      {
        id: 'roles',
        title: 'Roles',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/apps/roles'
      },
      {
        id: 'permissions',
        title: 'Permissions',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/apps/permissions'
      },
      {
        id: 'emailConfiguraion',
        title: 'Email Configuration',
        icon: <Circle size={12} />,
        show: [IS_ADMIN],
        navLink: '/apps/email-configurations'
      }
      // {
      //   id: 'reasonMaster',
      //   title: 'Reason Master',
      //   icon: <Circle size={12} />,
      //   show: [IS_ADMIN],
      //   navLink: '/apps/reason-master'
      // }
    ]
  }

  // {
  //   id: 'bulkupload',
  //   title: 'Bulk Upload',
  //   icon: <Upload size={10} />,
  //   show: [IS_ADMIN, IS_MENTOR],
  //   navLink: '/apps/bulkupload'
  // }
  // {
  //   id: 'projectmapping',
  //   title: 'User Projects',
  //   icon: <Trello size={12} />,
  //   show: [IS_ADMIN],
  //   navLink: '/apps/user-project'
  // }
]
