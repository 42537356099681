import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from './../../../../../app.config'
import axios from 'axios'

export const getReviewScheduled = createAsyncThunk(
  'reports/reviewScheduled',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/dashboard/review-schedules',
      { params }
    )
    return {
      allReviewsAllDone: response.data.data,
      totalPages: response?.data?.count?.total
    }
  }
)
export const ReviewScheduled = createSlice({
  name: 'reviewsScheduledMaster',
  initialState: {
    allReviewsAllDone: [],
    loader: false,
    params: {
      pageNo: 1,
      pageSize: 10
    },
    totalPages: 0,
    filterData: null
  },
  reducers: {
    setParams: (state, action) => {
      state.params = action.payload
    },
    setPageNo: (state, action) => {
      state.params.pageNo = action.payload
    },
    setPageSize: (state, action) => {
      state.params.pageSize = action.payload
    },
    setFilterData: (state, action) => {
      if (action.payload === null) {
        state.filterData = null
      } else {
        state.filterData = action.payload
      }
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReviewScheduled.fulfilled, (state, action) => {
        state.loader = false
        state.allReviewsAllDone = action.payload.allReviewsAllDone
        state.totalPages = action.payload.totalPages
      })
      .addCase(getReviewScheduled.pending, (state, action) => {
        state.loader = true
      })
      .addCase(getReviewScheduled.rejected, (state, action) => {
        state.loader = false
      })
  }
})
export const {
  setParams,
  setPageNo,
  setPageSize,
  setFilterData,
  selectClearFilter,
  setLoader
} = ReviewScheduled.actions

export default ReviewScheduled.reducer
