import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'
import axios from 'axios'
import toast from 'react-hot-toast'
//import { dispatch } from 'react-hot-toast/dist/core/store'

export const getReasonMaster = createAsyncThunk(
  'get/reasonMaster',
  async () => {
    const res = await axios.get(baseUrl() + '/reason')
    console.log(res)
    return {
      allReason: res.data.data
    }
  }
)

export const addReasonMaster = createAsyncThunk(
  'add/reasonMaster',
  async ({ params }, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + '/reason', params)
      dispatch(getReasonMaster())
      toast.success(res?.data?.message)
    } catch (error) {
      toast.error('An error occured')
    }
  }
)

export const updateReasonMaster = createAsyncThunk(
  'update/reasonMaster',
  async ({ params, id }, { dispatch }) => {
    try {
      const res = await axios.put(baseUrl() + `/reason/${id}`, params)
      dispatch(getReasonMaster())
      toast.success(res?.data?.message)
    } catch (error) {
      toast.error('An error occured')
    }
  }
)

export const deleteReasonMaster = createAsyncThunk(
  'delete/reasonMaster',
  async ({ id }, { dispatch }) => {
    try {
      const res = await axios.delete(baseUrl() + `/reason/${id}`)
      dispatch(getReasonMaster())
      toast.success(res?.data?.message)
    } catch (error) {
      toast.error('An error occured')
    }
  }
)

export const reasonMasterSlice = createSlice({
  name: 'reviewStatus',
  initialState: {
    allReason: [],
    submitLoader: false
  },
  reducers: {
    setSubmitLoader: (state, action) => {
      state.submitLoader = action.payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getReasonMaster.fulfilled, (state, action) => {
        state.allReason = action.payload.allReason
        state.submitLoader = false
      })
      .addCase(getReasonMaster.pending, (state) => {
        state.submitLoader = true
      })
      .addCase(getReasonMaster.rejected, (state) => {
        state.submitLoader = false
        state.allReason = []
      })

      // Handling addReasonMaster
      .addCase(addReasonMaster.fulfilled, (state, action) => {
        state.submitLoader = false
      })
      .addCase(addReasonMaster.pending, (state) => {
        state.submitLoader = true
      })
      .addCase(addReasonMaster.rejected, (state) => {
        state.submitLoader = false
      })

      // Handling updateReasonMaster
      .addCase(updateReasonMaster.fulfilled, (state, action) => {
        state.submitLoader = false
      })
      .addCase(updateReasonMaster.pending, (state) => {
        state.submitLoader = true
      })
      .addCase(updateReasonMaster.rejected, (state) => {
        state.submitLoader = false
      })

      // Handling deleteReasonMaster
      .addCase(deleteReasonMaster.fulfilled, (state, action) => {
        state.submitLoader = false
      })
      .addCase(deleteReasonMaster.pending, (state) => {
        state.submitLoader = true
      })
      .addCase(deleteReasonMaster.rejected, (state) => {
        state.submitLoader = false
      })
  }
})

export default reasonMasterSlice.reducer
