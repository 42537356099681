// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getReviewsNotDone = createAsyncThunk(
  'reports/reviewsNotDone',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/dashboard/reviews/not-done',
      { params }
    )
    console.log(response)
    return {
      allReviewsAllDone: response.data.data,
      totalPages: response?.data?.count?.total
    }
  }
)

export const ReviewsNotDoneSlice = createSlice({
  name: 'reviewsNotDoneMaster',
  initialState: {
    allReviewsAllDone: [],
    loader: false,
    params: {
      pageNo: 1,
      pageSize: 10
    },
    totalPages: 0,
    filterData: null
  },
  reducers: {
    setParams: (state, action) => {
      state.params = {
        // ...state.params,
        ...action.payload
      }
    },
    setPageNo: (state, action) => {
      state.params.pageNo = action.payload
    },
    setPageSize: (state, action) => {
      state.params.pageSize = action.payload
    },
    setFilterData: (state, action) => {
      if (action.payload === null) {
        state.filterData = null
      } else {
        state.filterData = action.payload
      }
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReviewsNotDone.fulfilled, (state, action) => {
        ;(state.loader = false),
          (state.allReviewsAllDone = action.payload.allReviewsAllDone)
        state.totalPages = action.payload.totalPages
      })
      .addCase(getReviewsNotDone.pending, (state, action) => {
        state.loader = true
      })
      .addCase(getReviewsNotDone.rejected, (state, action) => {
        state.loader = false
      })
  }
})

export const {
  setParams,
  setPageNo,
  setPageSize,
  setFilterData,
  selectClearFilter,
  setLoader
} = ReviewsNotDoneSlice.actions

export default ReviewsNotDoneSlice.reducer
