import React from 'react'
import { Filter } from 'react-feather'
import { Alert, Row, Col, Label, Form, Input, Button } from 'reactstrap'
import FilterButton from './FilterButton'
import UserTabSwitch from '../../views/apps/master/user/UserTabSwitch'
import { hasPermission } from '../Utils'

export const CustomHeader = ({
  searchTerm,
  exportClick,
  exportName,
  exportBtn = false,
  handleFilter,
  setSearchTerm,
  addClick,
  addName,
  search = true,
  showButton = true,
  importBtn = false,
  title,
  importName,
  importClick,
  permissions,
  clickFilterIconProps,
  clickClearFilterIconProps,
  filter,
  setFilter,
  clearFilter = false,
  setClearFilter,
  showFilter = false,
  filterComponent: FilterComponent,
  setShowFilter,
  showDeletedUser,
  schedule,
  setSchedule,
  showSchedule = false,
  setAddReason,
  addReason = false
}) => {
  return (
    <>
      <Row className="text-nowrap w-100 my-75 g-0 permission-header">
        <Col xs={12} md={2} className={`d-flex align-items-center mt-lg-0`}>
          <h3 className="text-height ">{title}</h3>
        </Col>
        <Col xs={12} md={10}>
          <div className="myCustomHeader d-flex align-items-center justify-content-md-end justify-content-start flex-md-nowrap flex-wrap mt-lg-0 mt-0">
            <div className="d-flex align-items-center me-1">
              {/* {filter && (
                <FilterButton
                  className="me-1 font-medium-2"
                  clickFilterIconProps={clickFilterIconProps}
                  clickClearFilterIconProps={clickClearFilterIconProps}
                  filterComponent={FilterComponent}
                />
              )} */}
              {search && (
                <>
                  <label className="mb-0" htmlFor="search-permission">
                    Search:
                  </label>
                  <Input
                    type="text"
                    value={searchTerm}
                    placeholder="Search"
                    id="search-permission"
                    className="ms-50 w-100"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </>
              )}
            </div>
            {showSchedule && (
              <div className="d-flex align-items-center me-1">
                <div className="filterWrapper">
                  <input
                    className="header__sec2-btn btn btn-primary"
                    type="button"
                    value="Schedule a Review"
                    onClick={() => {
                      setSchedule(true)
                    }}
                  />
                </div>
              </div>
            )}
            {addReason && (
              <div className="d-flex align-items-center me-1">
                <div className="filterWrapper">
                  <input
                    className="header__sec2-btn btn btn-primary"
                    type="button"
                    value="Add a Reason"
                    onClick={() => {
                      setAddReason(true)
                    }}
                  />
                </div>
              </div>
            )}

            {showFilter && (
              <div className="d-flex align-items-center me-1">
                {/* <Filter
                  className="me-1 font-medium-2 cursor-pointer"
                  onClick={() => {
                    setFilter({
                      show: true,
                      ...filter?.filterData
                    })
                  }}
                ></Filter> */}

                <div className="filterWrapper">
                  <input
                    className="header__sec2-btn btn btn-primary"
                    type="button"
                    value="Filter"
                    onClick={() => {
                      setFilter({
                        show: true,
                        ...filter?.filterData
                      })
                    }}
                  />
                </div>
                {clearFilter && (
                  <Button
                    outline
                    color="primary"
                    className="fw-bolder ms-1"
                    onClick={() => {
                      setClearFilter(false)
                    }}
                  >
                    Clear
                  </Button>
                )}
              </div>
            )}
            {permissions && !showDeletedUser && (
              <Button
                className="add-permission mb-sm-0 header__sec2-btn me-1"
                color="primary"
                onClick={addClick}
              >
                {addName}
              </Button>
            )}
            {importBtn && (
              <Button
                className="header__sec2-btn btn btn-primary me-1"
                color="primary"
                onClick={() => importClick()}
              >
                {importName}
              </Button>
            )}
            {exportBtn && (
              <button
                className="header__sec2-btn btn btn-primary"
                type="button"
                value="filter"
                onClick={() => exportClick()}
              >
                Export
              </button>
            )}
          </div>
        </Col>
      </Row>
      {hasPermission('IsAdmin') &&
        window.location?.pathname === '/apps/master/user' && (
          <UserTabSwitch setSearchTerm={setSearchTerm} />
        )}
    </>
  )
}

export default CustomHeader
