import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../app.config'
import axios from 'axios'
import toast from 'react-hot-toast'

// Async Thunks
export const getAllFilterData = createAsyncThunk(
  'get/getAllFilterData',
  async (id) => {
    const response = await axios.get(
      baseUrl() + '/usertechnologies/usersTech/' + id
    )
    return response?.data?.data || []
  }
)

export const sendReviewCallInvite = createAsyncThunk(
  'add/sendReviewCallInvite',
  async (params, { dispatch }) => {
    dispatch(setSubmitLoader({ loading: true }))
    try {
      const res = await axios.post(
        baseUrl() + '/mailconfiguration/reviewCall-invite',
        params
      )
      toast.success(res?.data?.message)
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.response?.data?.errorMessage
      )
    }
    dispatch(setSubmitLoader({ loading: false }))
  }
)

export const sendRescheduleReviewCallInvite = createAsyncThunk(
  'add/sendRescheduleReviewCallInvite',
  async ({ params, id }, { dispatch }) => {
    dispatch(setSubmitLoader({ loading: true }))
    try {
      const res = await axios.post(
        baseUrl() + '/mailconfiguration/reschedule/' + id,
        params
      )
      toast.success(res?.data?.message)
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.response?.data?.errorMessage
      )
    }
    dispatch(setSubmitLoader({ loading: false }))
  }
)

export const getReviewStatus = createAsyncThunk(
  'get/reviewStatus',
  async () => {
    const res = await axios.get(baseUrl() + '/reason')
    return res.data.data
  }
)

// Slice
export const mailconfigurationSlice = createSlice({
  name: 'mailConfiguration',
  initialState: {
    isLoading: false,
    allFilterData: [],
    allReviewStatus: [],
    submitLoader: { loading: false, id: null }
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSubmitLoader: (state, action) => {
      ;(state.submitLoader.loading = action.payload.loading),
        (state.submitLoader.id = action.payload.id)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFilterData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllFilterData.fulfilled, (state, action) => {
        state.allFilterData = action.payload
        state.isLoading = false
      })
      .addCase(getAllFilterData.rejected, (state) => {
        state.isLoading = false
      })
      //
      .addCase(getReviewStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getReviewStatus.fulfilled, (state, action) => {
        state.allReviewStatus = action.payload
        state.isLoading = false
      })
      .addCase(getReviewStatus.rejected, (state) => {
        state.isLoading = false
      })
      //
      .addCase(sendReviewCallInvite.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendReviewCallInvite.fulfilled, (state, action) => {
        state.allFilterData = action.payload
        state.isLoading = false
      })
      .addCase(sendReviewCallInvite.rejected, (state) => {
        state.isLoading = false
      })
      //
      .addCase(sendRescheduleReviewCallInvite.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendRescheduleReviewCallInvite.fulfilled, (state, action) => {
        state.allReviewStatus = action.payload
        state.isLoading = false
      })
      .addCase(sendRescheduleReviewCallInvite.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const { setSubmitLoader } = mailconfigurationSlice.actions
export default mailconfigurationSlice.reducer
